import React, { useEffect } from "react";
import Heading from "../../common/Heading";
import "./hero.css";

const Hero = () => {
  useEffect(() => {
    const scrollHeroIntoView = () => {
      const heroSection = document.querySelector(".hero");
      heroSection.scrollIntoView({ behavior: "smooth" });
    };

    scrollHeroIntoView();
  }, []);

  return (
    <section className="hero">
      <div className="container">
        <div className="content">
          <Heading 
            title="TSSM Property Services Limited"
            subtitle="is a reputable company specializing in comprehensive property management solutions."
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
