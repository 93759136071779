import React, { useState, useEffect } from "react";
import Heading from "../../common/Heading";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import "./style.css";

const Location = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const firestore = firebase.firestore();
        const locationsRef = firestore.collection('location');
        const snapshot = await locationsRef.get();
        const locationsData = snapshot.docs.map((doc) => {
          const locationData = doc.data();
          return {
            id: doc.id,
            name: locationData.name,
            Villas: locationData.Villas,
            Apartments: locationData.Apartments,
            Offices: locationData.Offices,
            coverUrl: locationData.cover
          };
        });
        setLocations(locationsData);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  return (
    <>
      <section className="location padding">
        <div className="container">
          <Heading title="Explore By Location" subtitle="Call or visit us at one of our different locations." />

          <div className="content grid3 mtop">
            {locations.map((item, index) => (
              <div className="box" key={index}>
                <img src={item.coverUrl} alt="" />
                
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;
