import React, { useState } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import img from "../images/baner.jpg";
import Back from "../common/Back";
import "./contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add data to Firestore
      await firebase.firestore().collection('contacts').add(formData);
      alert('Message sent was submitted successfully, thank you we will reply you through your email!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Error submitting data. Please try again later.');
    }
  };

  return (
    <>
      <section className='contact mb'>
        <Back name='Contact Us' title='Get Helps & Friendly Support on ' cover={img} />
        <div className='container'>
          <form className='shadow' onSubmit={handleSubmit}>
            <h4>Fillup The Form</h4> <br />
            <div>
              <input type='text' name='name' placeholder='Name' value={formData.name} onChange={handleChange} />
              <input type='text' name='email' placeholder='Email' value={formData.email} onChange={handleChange} />
            </div>
            <input type='text' name='subject' placeholder='Subject' value={formData.subject} onChange={handleChange} />
            <textarea cols='30' rows='10' name='message' value={formData.message} onChange={handleChange}></textarea>
            <button type='submit'>Submit Request</button>
          </form>
        </div>
      </section>
    </>
  );
}

export default Contact;
