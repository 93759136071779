import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import firebase from "firebase/compat/app"; // Import firebase app
import "firebase/compat/firestore";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA8zdVdj4mm2vs_W5CzSdrRTdAPZCYPOXg",
  authDomain: "tssmproperties.firebaseapp.com",
  projectId: "tssmproperties",
  storageBucket: "tssmproperties.appspot.com",
  messagingSenderId: "282849750102",
  appId: "1:282849750102:web:7a376d762a27e7c1419ff7",
  measurementId: "G-GJSHVQ4532"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
