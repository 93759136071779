import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../images/baner.jpg"
import "./about.css"

const About = () => {
  return (
    <>
      <section className='about'>
        <Back name='About Us' title='About Us - Who We Are?' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Our range of services includes' subtitle='For those looking to rent a property.' />

            <p>We offer a wide selection of well-maintained and desirable properties.
Our team works closely with tenants to understand their requirements and find the perfect rental property that suits their needs and budget.</p>
           
            <Heading title='' subtitle='When it comes to property sales and buying.' />
<p>we provide expert guidance and support throughout the entire process.
Our experienced agents have an in-depth knowledge of the local market and can assist clients in making informed decisions.
Whether you are selling or buying a property, we ensure a smooth and efficient transaction.</p>
            
          </div>
          <div className='right row'>
            <img src='./images/TSSM LTD PROPERTY FLYER march.JPG' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
