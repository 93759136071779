import React, { useState } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "./footer.css";

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const firestore = firebase.firestore();
      const subscribersRef = firestore.collection('subscribers');
      await subscribersRef.add({
        email: email,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      });
      setEmail('');
      alert('Thank you for subscribing!');
    } catch (error) {
      console.error('Error adding subscriber:', error);
      alert('An error occurred while subscribing. Please try again later.');
    }
  };

  return (
    <>
      <section className='footerContact'>
        <div className='container'>
          <div className='send flex'>
            <div className='text'>
              <h1>Property Management</h1>
              <p>Our team of dedicated professionals handles rent collection.</p>
            </div>
           
          </div>
        </div>
      </section>

      <footer>
        <div className='container'>
          <div className='box'>
            <div className='logo'>
            <img src='./images/favicon.jpg' alt='' style={{ width: '90px', height: 'auto' }} />
              <h2>Do You Need Help With Anything?</h2>
              <p>Contact us today to experience our exceptional services and let us take care of your property concerns.<br/>Call us directly?<br/>
+256 774564266<br/>
+256 706202038<br/>Email : tssmpropertyservices@gmail.com</p>

              <form onSubmit={handleSubmit} className='input flex'>
                <input type='email' placeholder='Email Address' value={email} onChange={(e) => setEmail(e.target.value)} required />
                <button type='submit'>Subscribe</button>
              </form>
            </div>
          </div>

          {/* Your existing footer content goes here */}
          
        </div>
      </footer>
      <div className='legal'>
        <span id="Year" style={{ color: '#fff', fontWeight: 'bold' }}>
          &copy; TSSM property {new Date().getFullYear()}
        </span>
      </div>
    </>
  );
}

export default Footer;
