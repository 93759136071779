import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const RecentCard = () => {
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [contact, setContact] = useState('');
  const [location, setLocation] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isAdmin, setIsAdmin] = useState(false); // Check if user is admin

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firestore = firebase.firestore();
        const collection = firestore.collection('property');
        const snapshot = await collection.get();
        const fetchedData = snapshot.docs.map((doc) => doc.data());
        setData(fetchedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Check if user is admin
    checkAdmin();
  }, []);

  // Function to check if user is admin
  const checkAdmin = async () => {
    try {
      // Make a request to your backend to verify if the user is an admin
      // Example: const response = await fetch('/api/checkAdmin');
      // const data = await response.json();
      // setIsAdmin(data.isAdmin);

      // For demonstration purpose, assuming user is admin
      setIsAdmin(true);
    } catch (error) {
      console.error('Error checking admin status:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!isAdmin) {
        throw new Error('Unauthorized to send message');
      }

      const firestore = firebase.firestore();
      const messagesCollection = firestore.collection('messages');

      await messagesCollection.add({
        contact,
        location,
        name,
        email,
        message,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      });

      handleClosePopup(); // Close the popup form after sending message
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleImageClick = (imageData) => {
    setSelectedImage(imageData);
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <div className="content grid3 mtop">
        {data.map((val, index) => {
          const { cover, category, location, name, price, type } = val;
          return (
            <div className="box shadow" key={index}>
              <div className="img" onClick={() => handleImageClick(val)}>
                <img src={cover} alt="" />
              </div>
              <div className="text">
                <div className="category flex">
                  <span
                    style={{
                      background: category === 'For Sale' ? '#25b5791a' : '#ff98001a',
                      color: category === 'For Sale' ? '#25b579' : '#ff9800',
                    }}
                  >
                    {category}
                  </span>
                  <i className="fa fa-heart"></i>
                </div>
                <h4>{name}</h4>
                <p>
                  <i className="fa fa-location-dot"></i> {location}
                </p>
              </div>
              <div className="button flex">
                <div>
                  <button className="btn2">{price}</button> <label htmlFor="">UGX</label>
                </div>
                <span>{type}</span>
              </div>
            </div>
          );
        })}
      </div>
      {selectedImage && (
        <div style={{ position: 'fixed', top: '51%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#27ae60', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', zIndex: 999 }}>
          <button style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', zIndex: 1000 }} onClick={handleClosePopup}>X</button>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="contact" style={{ marginBottom: '5px' }}>Phone </label>
            <input type="text" id="contact" name="contact" value={contact} onChange={(e) => setContact(e.target.value)} required style={{ padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc' }} />
            <label htmlFor="location" style={{ marginBottom: '5px' }}>Your Location:</label>
            <input type="text" id="location" name="location" value={location} onChange={(e) => setLocation(e.target.value)} required style={{ padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc' }} />
            <label htmlFor="name" style={{ marginBottom: '5px' }}>Your Name:</label>
            <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required style={{ padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc' }} />
            <label htmlFor="email" style={{ marginBottom: '5px' }}>Email:</label>
            <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required style={{ padding: '8px', marginBottom: '10px', borderRadius: '4px', border: '1px solid #ccc' }} />
            <label htmlFor="message" style={{ marginBottom: '5px' }}>Send your interest in the text below with the property name and location:</label>
            <textarea id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} required style={{ padding: '8px', marginBottom: '20px', borderRadius: '4px', border: '1px solid #ccc', height: '100px' }}></textarea>
            <button type="submit" style={{ padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Send</button>
          </form>
        </div>
      )}
    </>
  );
};

export default RecentCard;
